<template>
  <span class="loader" :style="cssVariables" title="Gegevens worden opgehaald..." />
</template>

<script setup lang="ts">
  import {computed} from 'vue';

  import {match} from '@/js/util/pattern-match';

  interface Props {
    size?: 'small' | 'medium' | 'large';
  }

  const props = withDefaults(defineProps<Props>(), {
    size: 'medium',
  });

  const scale = computed(() => match(props.size)
    .with('small', () => 0.7)
    .with('medium', () => 1)
    .with('large', () => 2)
    .exhaustive());

  const cssVariables = computed(() => ({
    '--scale': scale.value,
  }));
</script>

<style lang="scss" scoped>
  // Inspired by https://cssloaders.github.io/

  @use "sass:math";
  @use "@/sass/variables";

  $loader-size-regular: 48px;

  .loader {
    width: calc(var(--scale) * $loader-size-regular);
    height: calc(var(--scale) * $loader-size-regular);
    border: 3px dotted variables.$ssl-blue;
    border-style: solid solid dotted dotted;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;

    &::after {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0;
      margin: auto;
      border: 3px dotted variables.$ssl-green;
      border-style: solid solid dotted;
      width: calc(var(--scale) * math.div($loader-size-regular, 2));
      height: calc(var(--scale) * math.div($loader-size-regular, 2));
      border-radius: 50%;
      animation: rotation-back 1s linear infinite;
      transform-origin: center center;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotation-back {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
</style>
