import {match, Pattern} from 'ts-pattern';

const matchAll = Pattern.any;
const notNull = Pattern.not(null);

export {
  match,
  Pattern,
  matchAll,
  notNull,
};
