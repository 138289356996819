/**
 * Materialize is our main css framework.
 */

import M from '@materializecss/materialize';
window.M = M;

const sidebarMenu = document.getElementById('nav-sidebar');
const mobileMenu = document.getElementById('nav-mobile')!;

const initCollapsibles = () => {
  if (sidebarMenu) {
    M.Collapsible.init(sidebarMenu, {accordion: false});
  }
  M.Collapsible.init(mobileMenu, {accordion: true});
  M.Collapsible.init(mobileMenu.querySelectorAll('.collapsible'), {accordion: true});
};

const highlightSidebar = () => {
  // Set active state on menu element
  const full_url = window.location.href;
  if (window.location.pathname === '/') {
    // Do not highlight on home page
    return;
  }
  const $navLinks = [
    ...sidebarMenu?.querySelectorAll<HTMLAnchorElement>('a.nav-link') ?? [],
    ...mobileMenu.querySelectorAll<HTMLAnchorElement>('a.nav-link'),
  ];

  // First look for an exact match including the search string
  let $currentPageLink = $navLinks.filter((node) => node.href === full_url);

  // If not found, look for the link that starts with the url
  if ($currentPageLink.length === 0) {
    $currentPageLink = $navLinks.filter((node) => (
      node.href.startsWith(full_url) || full_url.startsWith(node.href)
    ));
  }

  // Finally set links to active
  $currentPageLink.forEach(
    (node) => {
      // Set nav-link to active
      node.classList.add('active');
      // If link has a parent, mark it as active
      const li = node.closest('li.nav-item.top');
      const header = node.closest('div.collapsible-header');
      if (li !== null) {
        li.classList.add('active');
      }
      if (header !== null) {
        header.classList.add('active');
      }
    },
  );
};

const initMaterialize = async() => {
  const dropdowns = document.querySelectorAll('.topmenu .dropdown-trigger');

  const menuOptions = {};
  const dropdownOptions = {
    hover: true,
    coverTrigger: false,
    constrainWidth: false,
  };

  // init sidenav button and collapsing of mobile menu
  M.Sidenav.init(mobileMenu, menuOptions);
  // init dropdowns for menu
  M.Dropdown.init(dropdowns, dropdownOptions);

  // highlight sidebar and then init collapsibles (order matters to ensure active collapsibles are toggled)
  highlightSidebar();
  initCollapsibles();
};

// Immediately call initMaterialize
void initMaterialize();

/*
 * Do an additional update of Materialize fields when the page is loaded,
 * to ensure labels are rendered correctly (Vue might be filling values during load).
 */
window.addEventListener('load', async() => M.updateTextFields());
