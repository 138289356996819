import {Task, TaskStatus} from '@lit/task';
import {type LitElement} from 'lit';

import {endpoints} from './endpoints';

import {type VideoQuality} from '@/js/types/models/docentenpagina';

// See app/Http/Controllers/File/FileController@getUrl
interface FileResponse {
  url: string;
  title: string;
}

// See app/Http/Controllers/File/VideoController@getFiles
interface VideoFile {
  quality: VideoQuality;
  url: string;
}
interface VideoResponse {
  files: Array<VideoFile>;
  title: string;
}

type FileComponent = LitElement & {
  fileUuid: string;
};

class FileTask<T extends ReadonlyArray<unknown>, R> extends Task<T, R> {
  get isInteractable() {
    return this.status !== TaskStatus.ERROR;
  }

  // Runs a task if it has not run yet; otherwise return the existing result
  async lazyRun() {
    if (this.status === TaskStatus.INITIAL) {
      this.run();
    }

    return await this.taskComplete;
  }

  // Allows the task to autorun from now on and ensure it runs for a first time
  async boot() {
    this.autoRun = true;
    return await this.lazyRun();
  }
}

export type FetchFileTask = FileTask<Array<string>, FileResponse>;
export type FetchVideoTask = FileTask<Array<string>, VideoResponse>;

const toFileUrl = (baseUrl: string, uuid: string) => {
  const url = new URL(baseUrl, window.location.origin);
  url.search = new URLSearchParams({uuid}).toString();

  return url;
};

export const fetchItemTask = <R, T extends FileComponent>(element: T, baseUrl: string) => {
  // Create a task to fetch the file/video url based on the uuid
  const task = new FileTask(
    element,
    {
      task: async([fileUuid], {signal}) => $http.get<R>(toFileUrl(baseUrl, fileUuid).toString(), {signal})
        .then((response) => response.data),
      args: () => [element.fileUuid],
      autoRun: false,
    },
  );

  // Use intersection observer to wait with firing task until element is near viewport
  const intersectCallback: IntersectionObserverCallback = (entries, observer) => {
    if (entries.some((entry) => entry.isIntersecting)) {
      task.boot();
      observer.disconnect();
    }
  };
  const options: IntersectionObserverInit = {
    root: null,
    rootMargin: '25%',
    threshold: 0,
  };
  const observer = new IntersectionObserver(intersectCallback, options);
  observer.observe(element);

  return task;
};

export const fetchFileTask = <T extends FileComponent>(element: T) =>
  fetchItemTask<FileResponse, T>(element, endpoints.get.getFileUrl);

export const fetchVideoTask = <T extends FileComponent>(element: T) =>
  fetchItemTask<VideoResponse, T>(element, endpoints.get.getVideoFiles);
